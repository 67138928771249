import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import tg from "./assets/img/tg.png";
import tw from "./assets/img/tw.png";
import logo from "./assets/img/logo.svg";
import banner from "./assets/img/banner.png";
import r1 from "./assets/img/1.png";
import r2 from "./assets/img/2.png";
import "./App.css";

const CONTRACT = "0x13d612fbf7e333624Cb8653A72650090A0Ad03f1";

function App() {
  const [isCopy, setIsCopy] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 500,
      offset: 100,
      easing: "linear",
    });
  }, []);

  return (
    <div className="App">
      <header className="bg-[#057E03] py-6 lg:py-3">
        <div className="container mx-auto px-3 flex flex-col gap-3 items-center justify-between lg:flex-row lg:px-8">
          <div className="header-logo w-[200px] h-[50px]">
            <img src={logo} alt="" />
          </div>
          <ul className="flex text-base gap-4 font-title lg:gap-8 lg:text-xl">
            <li>
              <a className="">Home</a>
            </li>
            <li>
              <a className="">Tokenomics</a>
            </li>
            <li>
              <a className="">Rawrvelations</a>
            </li>
            <li>
              <a className="">Tax</a>
            </li>
            <li>
              <a className="">Roadmap</a>
            </li>
          </ul>
          <ul className="flex items-center gap-5">
            <li className="transition-all hover:-translate-y-2">
              <a
                className=""
                href="https://t.me/PepeBuddhaERC"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="w-[50px]" src={tg} alt="" />
              </a>
            </li>
            <li className="transition-all hover:-translate-y-2">
              <a
                href="https://twitter.com/PepeBuddhaERC"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="w-[50px]" src={tw} alt="" />
              </a>
            </li>
          </ul>
        </div>
      </header>

      <main>
        <section className="bg-[#057E03] bg-no-repeat bg-cover pt-16 lg:pt-[150px]">
          <div className="container mx-auto px-3 flex flex-col justify-between lg:flex-row lg:px-8">
            <div className="max-w-xl">
              <h1 className="font-title text-6xl lg:text-8xl">
                Pepe <span className="">Buddha</span>
              </h1>
              <h3 className="mt-12 mb-6 text-2xl">
                Enlightened Crypto, Enriching Lives
              </h3>
              <p className="">
                Unfolding in 2023, Pepe Buddha is not just a token but a legend.
                It's the birth of Zen in the frenzied world of memes,
                transcending chaos, and entering the halls of crypto history.
              </p>
              <div className="mt-10 grid grid-cols-2 gap-y-7 gap-x-4">
                <a
                  href="https://www.dextools.io/app/en/ether/pair-explorer/0x7978091dc237a434c981e4eba96461591ca23e00"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="border-2 border-[#1A9DF0] rounded text-center px-3 py-4 text-[#fff] font-bold transition-all hover:bg-[#1A9DF0]"
                >
                  BUY ON UNISWAP
                </a>
                <a
                  href="https://www.dextools.io/app/en/ether/pair-explorer/0x7978091dc237a434c981e4eba96461591ca23e00"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="border-2 border-[#1A9DF0] rounded text-center px-3 py-4 text-[#fff] font-bold transition-all hover:bg-[#1A9DF0]"
                >
                  CHART
                </a>
              </div>
            </div>
            <div className="max-w-lg">
              <img src={banner} alt="" />
            </div>
          </div>
        </section>

        <section className="bg-[#057E03] bg-no-repeat bg-cover bg-tokenomics py-[100px] bg-top relative z-10">
          <div className="container mx-auto px-3 lg:px-8" data-aos="fade-up">
            <h2 className="text-5xl font-title text-center">Tokenomics</h2>
            <div className="grid grid-cols-1 mt-[50px] lg:mt-[100px] lg:grid-cols-3">
              <div className="text-center py-10">
                <h3 className="text-gradient font-bold text-6xl mb-5">0%</h3>
                <span className="text-lg">Team Tokens</span>
              </div>
              <div className="text-center py-10">
                <h3 className="text-gradient font-bold text-6xl mb-5">93.1%</h3>
                <span className="text-lg">Liquidity Pool</span>
              </div>
              <div className="text-center py-10">
                <h3 className="text-gradient font-bold text-6xl mb-5">6.9%</h3>
                <span className="text-lg">Cex Wallet</span>
              </div>
            </div>

            <h2 className="text-5xl font-title text-center mt-[150px]">
              Contract
            </h2>
            <div className="text-center mt-12 ">
              <p
                className="inline-block py-4 px-8 text-base rounded-full text-center bg-gradient border-2 border-transparent cursor-pointer lg:px-10"
                onClick={() => {
                  setIsCopy(true);
                  return navigator.clipboard.writeText(CONTRACT);
                }}
              >
                {CONTRACT.substring(0, 8)}...
                {CONTRACT.substring(CONTRACT.length - 8)}
              </p>
              {isCopy && <span className="block mt-3">Copied!</span>}
            </div>
          </div>

          <div
            className="container mx-auto px-8 pt-[100px] flex flex-col justify-between gap-10 lg:flex-row"
            data-aos="fade-up"
          >
            <div className="max-w-2xl">
              <h2 className="text-5xl font-title">Pepe Buddha Sutras:</h2>
              <p className="mt-8">
                Picture Pepe the Frog, but not as you know him. He's swapped his
                smirk for a serene expression, meditating amidst the swirl of
                digits and decimals. Welcome to Pepe Buddha, where every
                transaction feels like a breath of calm in the crypto storm.
                <br />
                <br />
                When the digital sphere was just a wilderness of aimless
                memecoins, a tranquil force emerged: Pepe Buddha. Like a lotus
                blooming through the mud, this token changed the course of
                crypto history, transforming confusion into clarity.
                <br />
                <br />
                Pepe Buddha didn't just survive against market skeptics, it
                thrived. It stood strong amidst the turbulence, demonstrating
                miracles of growth and stability, showing us all that peace and
                prosperity can coexist in the crypto world.
                <br />
                <br />
                All these lessons and lore are etched in the Code of Calm, our
                blockchain sutras that offer investors a zen approach to market
                dynamics. It includes everything, from mindful investment
                mantras to enlightening market prophecies.
                <br />
                <br />
                At the heart of Pepe Buddha's teachings, is the prophecy of the
                Great Awakening - a time when every coin will find its path to
                prosperity, bringing about a serene revolution in the crypto
                universe. Join us as we journey towards enlightenment, one token
                at a time. Welcome to Pepe Buddha, where blockchain meets
                tranquility.
              </p>
            </div>
            <div className="max-w-md">
              <img src={r1} alt="" />
            </div>
          </div>
        </section>

        <section
          className="bg-[#057E03] bg-no-repeat bg-cover py-[150px] bg-top -mt-16 relative z-1 lg:py-[200px]"
          data-aos="fade-up"
        >
          <div className="container mx-auto px-3 lg:px-8">
            <h2 className="text-5xl font-title text-center">Tax</h2>
            <h4 className="text-center text-xl  mt-5">
              No Taxes, No Blasphemy
            </h4>
            <div className="max-w-3xl mx-auto mt-[150px] lg:mt-[100px]">
              <img src={r2} alt="" />
            </div>
            <div className="text-center absolute top-[40%] left-[20%]">
              <h3 className="text-gradient font-bold text-6xl mb-5">0%</h3>
              <span className="text-lg">Sell</span>
            </div>
            <div className="text-center absolute top-[40%] right-[20%] lg:bottom-48 lg:top-auto">
              <h3 className="text-gradient font-bold text-6xl mb-5">0%</h3>
              <span className="text-lg">Buy</span>
            </div>
          </div>
        </section>

        <section
          className="bg-[#057E03] bg-no-repeat bg-cover pt-[100px] pb-[50px] bg-top -mt-16 relative lg:pt-[100px]"
          data-aos="fade-up"
        >
          <div className="container mx-auto px-3 lg:px-8">
            <h2 className="text-5xl font-title text-center">pepe Buddha</h2>
            <ul className="flex items-center gap-5 mt-10 justify-center">
              <li className="transition-all hover:-translate-y-2">
                <a
                  href="https://t.me/PepeBuddhaERC"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="w-[50px]" src={tg} alt="" />
                </a>
              </li>
              <li className="transition-all hover:-translate-y-2">
                <a
                  href="https://twitter.com/PepeBuddhaERC"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="w-[50px]" src={tw} alt="" />
                </a>
              </li>
            </ul>
          </div>
        </section>
      </main>
    </div>
  );
}

export default App;
